import { render, staticRenderFns } from "./ParentAccount.vue?vue&type=template&id=22500cc3&scoped=true&"
import script from "./ParentAccount.vue?vue&type=script&lang=ts&"
export * from "./ParentAccount.vue?vue&type=script&lang=ts&"
import style0 from "./ParentAccount.vue?vue&type=style&index=0&id=22500cc3&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22500cc3",
  null
  
)

export default component.exports