var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticClass:"grading-viewer-modal",attrs:{"visible":_vm.visible,"title":_vm.$t(("setup.grading." + _vm.type + "Grading")),"destroyOnClose":"","centered":"","footer":_vm.type === 'view' ? null : undefined,"maskClosable":false,"width":900},on:{"cancel":_vm.closeModal,"ok":_vm.closeModal}},[(_vm.type !== 'view')?[_c('a-form-model',{ref:"gradingForm",attrs:{"model":_vm.gradingFrom,"autocomplete":"nope"}},[_c('a-form-model-item',{attrs:{"label":_vm.$t('common.name'),"rules":[
          {
            required: true,
            message: _vm.$t('tips.enterName'),
          },
          {
            max: 200,
            message: _vm.$t('tips.textLimit', { num: 200 }),
          } ],"prop":"name","required":""}},[_c('a-input',{attrs:{"placeholder":_vm.$t('placeholder.enterName')},model:{value:(_vm.gradingFrom.name),callback:function ($$v) {_vm.$set(_vm.gradingFrom, "name", $$v)},expression:"gradingFrom.name"}})],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('setup.grading.targetGrade'),"prop":"sectionIds","rules":[
          {
            required: true,
            message: _vm.$t('tips.selectTargetGrade'),
          } ]}},[_c('a-select',{attrs:{"mode":"multiple","showArrow":"","placeholder":_vm.$t('common.select'),"getPopupContainer":function () { return _vm.$document.body; },"disabled":true},model:{value:(_vm.gradingFrom.sectionIds),callback:function ($$v) {_vm.$set(_vm.gradingFrom, "sectionIds", $$v)},expression:"gradingFrom.sectionIds"}},_vm._l((_vm.grades),function(item){return _c('a-select-option',{key:item.key},[_vm._v(_vm._s(_vm.locale === 'zh' ? item.value : item.enValue || item.value))])}),1)],1),_c('a-form-model-item',[_c('a-table',{attrs:{"columns":_vm.columns,"pagination":false,"loading":_vm.loading,"dataSource":_vm.data,"rowKey":function (record, index) { return index; }},scopedSlots:_vm._u([{key:"percentRange",fn:function(text, record, index){return [_c('div',{class:{ 'has-error': record.pError },staticStyle:{"position":"relative"}},[_c('a-input-number',{staticStyle:{"width":"65px"},attrs:{"value":record.lower,"min":_vm.data[index + 1] ? _vm.data[index + 1].lower + 1 : 0,"max":record.higher},on:{"change":function (val) { return _vm.onNumberChange(val, record, index); },"blur":function($event){return _vm.validateEmpty(record, 'percentRange')}}}),_vm._v(" "+_vm._s((" ~ " + (record.higher)))+" "),(record.pError)?_c('span',{staticClass:"error-span"},[_vm._v(_vm._s(_vm.$t('common.enter')))]):_vm._e()],1)]}},{key:"level",fn:function(text, record){return [_c('div',{class:{ 'has-error': record.lError },staticStyle:{"position":"relative"}},[_c('a-input',{on:{"blur":function($event){return _vm.validateEmpty(record, 'level')}},model:{value:(record.grade),callback:function ($$v) {_vm.$set(record, "grade", $$v)},expression:"record.grade"}}),(record.lError)?_c('span',{staticClass:"error-span"},[_vm._v(_vm._s(_vm.$t('common.enter')))]):_vm._e()],1)]}},{key:"description",fn:function(text, record){return [_c('div',{staticClass:"bottom",class:{ 'has-error': record.dError },staticStyle:{"position":"relative"}},[_c('a-textarea',{attrs:{"autoSize":{
                  minRows: 4,
                  maxRows: 8,
                }},on:{"change":function (val) { return _vm.validateLimit(record, 'descr'); },"blur":function($event){return _vm.validateLimit(_vm.val, record, 'descr')}},model:{value:(record.description),callback:function ($$v) {_vm.$set(record, "description", $$v)},expression:"record.description"}}),(record.dError)?_c('span',{staticClass:"error-span"},[_vm._v(_vm._s(_vm.$t('tips.textLimit', { num: 2000 })))]):_vm._e()],1)]}},{key:"lom",fn:function(text, record){return [_c('div',{staticClass:"bottom flex-style",class:{ 'has-error': record.lomAbbrError },staticStyle:{"position":"relative","margin-bottom":"8px"}},[_c('span',{staticStyle:{"flex-shrink":"0","line-height":"32px"}},[_vm._v("Abbr：")]),_c('a-input',{on:{"change":function (val) { return _vm.validateLimit(record, 'lomAbb'); },"blur":function($event){return _vm.validateLimit(record, 'lomAbb')}},model:{value:(record.lomAbb),callback:function ($$v) {_vm.$set(record, "lomAbb", $$v)},expression:"record.lomAbb"}}),(record.lomAbbrError)?_c('span',{staticClass:"error-span"},[_vm._v(_vm._s(_vm.$t('tips.textLimit', { num: 10 })))]):_vm._e()],1),_c('div',{staticClass:"bottom flex-style",class:{ 'has-error': record.lomError },staticStyle:{"position":"relative"}},[_c('span',{staticStyle:{"flex-shrink":"0","line-height":"32px"}},[_vm._v("Desc：")]),_c('a-textarea',{attrs:{"autoSize":{
                  minRows: 4,
                  maxRows: 8,
                }},on:{"change":function (val) { return _vm.validateLimit(record, 'lom'); },"blur":function($event){return _vm.validateLimit(record, 'lom')}},model:{value:(record.lom),callback:function ($$v) {_vm.$set(record, "lom", $$v)},expression:"record.lom"}}),(record.lomError)?_c('span',{staticClass:"error-span"},[_vm._v(_vm._s(_vm.$t('tips.textLimit', { num: 2000 })))]):_vm._e()],1)]}},{key:"operations",fn:function(text, record, index){return [_c('a-button',{staticClass:"link-btn",attrs:{"type":"link","disabled":index === 0},on:{"click":function($event){return _vm.deleteItem(record, index)}}},[_vm._v(" "+_vm._s(_vm.$t('common.delete'))+" ")])]}}],null,false,2442959165)})],1)],1)]:[_c('div',{staticClass:"info-container"},[_c('span',[_vm._v(_vm._s(_vm.$t('common.name'))+"：")]),_c('span',[_vm._v(_vm._s(_vm.info.name))])]),_c('div',{staticClass:"info-container"},[_c('span',[_vm._v(_vm._s(_vm.$t('setup.grading.targetGrade'))+"：")]),_c('span',[_vm._v(_vm._s((_vm.info.sections || []).map(function (item) { return item.name; }).join('、')))])]),_c('a-table',{attrs:{"columns":_vm.columns,"pagination":false,"loading":_vm.loading,"dataSource":_vm.data,"rowKey":function (record) { return record.grade; }},scopedSlots:_vm._u([{key:"percentRange",fn:function(text, record){return [_vm._v(" "+_vm._s(((record.lower) + " ~ " + (record.higher)))+" ")]}},{key:"description",fn:function(text){return [_c('flex-tooltip',{attrs:{"value":text}})]}},{key:"lom",fn:function(record){return [_c('flex-tooltip',{attrs:{"value":("Abbr: " + (record.lomAbb || ''))}}),_c('flex-tooltip',{attrs:{"value":("Desc: " + (record.lom || ''))}})]}}])})],_c('template',{slot:"footer"},[_c('a-button',{on:{"click":_vm.closeModal}},[_vm._v(_vm._s(_vm.$t('common.cancel')))]),_c('a-button',{attrs:{"type":"primary","disabled":_vm.errorFlag},on:{"click":_vm.confirm}},[_vm._v(_vm._s(_vm.$t('common.confirm')))])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }