import { render, staticRenderFns } from "./StandardEditor.vue?vue&type=template&id=ddfe6c18&scoped=true&"
import script from "./StandardEditor.vue?vue&type=script&lang=ts&"
export * from "./StandardEditor.vue?vue&type=script&lang=ts&"
import style0 from "./StandardEditor.vue?vue&type=style&index=0&id=ddfe6c18&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddfe6c18",
  null
  
)

export default component.exports