import { render, staticRenderFns } from "./RecycleBin.vue?vue&type=template&id=31c64378&scoped=true&"
import script from "./RecycleBin.vue?vue&type=script&lang=ts&"
export * from "./RecycleBin.vue?vue&type=script&lang=ts&"
import style0 from "./RecycleBin.vue?vue&type=style&index=0&id=31c64378&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31c64378",
  null
  
)

export default component.exports