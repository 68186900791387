var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"standard-editor"},[(['add', 'edit'].includes(_vm.$route.params.type))?_c('a-form-model',{ref:"standardForm",staticClass:"standard-form",attrs:{"model":_vm.standardEditor}},[_c('a-form-model-item',{attrs:{"label":_vm.$t('common.name'),"rules":[
        {
          max: 200,
          message: _vm.$t('tips.textLimit', { num: 200 }),
        },
        {
          required: true,
          whitespace: true,
          message: _vm.$t('tips.enterName'),
        },
        {
          validator: _vm.validatorStandardName,
          trigger: ['change', 'blur'],
        } ],"labelCol":{ span: 7 },"wrapperCol":{ span: 12 },"prop":"standardName"}},[_c('a-input',{staticStyle:{"width":"100%"},attrs:{"placeholder":_vm.$t('common.enter')},model:{value:(_vm.standardEditor.standardName),callback:function ($$v) {_vm.$set(_vm.standardEditor, "standardName", $$v)},expression:"standardEditor.standardName"}})],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('graduation.requirements'),"required":"","labelCol":{ span: 7 },"wrapperCol":{ span: 12 }}},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"title-bar"}),_c('span',{staticClass:"title-text"},[_vm._v(_vm._s(_vm.$t('graduation.compulsory')))])])]),_c('a-row',{attrs:{"gutter":[8, 16]}},[_c('a-col',{attrs:{"span":12,"offset":7}},[_vm._l((_vm.compulsory),function(item,index){return _c('div',{key:item.conditionId || index,staticClass:"single-course-arrange"},[_c('div',{staticClass:"course-title"},[_vm._v(" "+_vm._s(_vm.$t('graduation.courseAdmission', { index: index + 1 }))+" "),_c('div',{staticClass:"button-container"},[_c('a-tooltip',{attrs:{"title":_vm.$t('common.delete')}},[(_vm.compulsory.length > 1)?_c('a-icon',{staticClass:"dynamic-delete-button",attrs:{"type":"delete"},on:{"click":function () { return _vm.removeCourseAdmission(index); }}}):_vm._e()],1)],1)]),_c('a-form-model-item',{attrs:{"label":_vm.$t('graduation.title'),"rules":[
              {
                validator: _vm.validateText,
                trigger: ['change', 'blur'],
                condition: 'compulsory',
                textType: 'title',
                index: index,
              } ],"prop":"conditions","required":""}},[_c('a-input',{staticStyle:{"width":"100%"},attrs:{"placeholder":_vm.$t('common.enter')},model:{value:(item.title),callback:function ($$v) {_vm.$set(item, "title", $$v)},expression:"item.title"}})],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('common.instruction'),"rules":[
              {
                validator: _vm.validateText,
                trigger: ['change', 'blur'],
                condition: 'compulsory',
                textType: 'remark',
                index: index,
              } ],"prop":"conditions","required":""}},[_c('a-textarea',{staticStyle:{"width":"100%"},attrs:{"placeholder":_vm.$t('common.enter'),"autoSize":{
                minRows: 4,
                maxRows: 8,
              }},model:{value:(item.remark),callback:function ($$v) {_vm.$set(item, "remark", $$v)},expression:"item.remark"}})],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('common.subject'),"rules":[
              {
                required: true,
                message: _vm.$t('tips.enterInstruction'),
              } ],"prop":"conditions"}},[_c('a-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":_vm.$t('tips.selectSubject'),"mode":"multiple","show-search":"","filter-option":_vm.filterOption},on:{"change":function($event){return _vm.validateCourse(index)}},model:{value:(item.subjectIds),callback:function ($$v) {_vm.$set(item, "subjectIds", $$v)},expression:"item.subjectIds"}},_vm._l((_vm.subjects),function(item){return _c('a-select-option',{key:item.key,attrs:{"disabled":_vm.validateSubjects(index, item.key)}},[_vm._v(_vm._s(_vm.locale === 'zh' ? item.value : item.enValue || item.value))])}),1)],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('graduation.mark'),"rules":[
              {
                required: true,
                message: _vm.$t('tips.enterCredit'),
                trigger: ['change', 'blur'],
              },
              {
                validator: _vm.validateCredit,
                trigger: ['change', 'blur'],
                creditType: 'total',
                index: index,
              } ],"prop":"conditions"}},[_c('a-input-number',{attrs:{"precision":2,"min":0,"max":999,"placeholder":_vm.$t('common.enter')},on:{"change":_vm.validateCredits},model:{value:(item.credits),callback:function ($$v) {_vm.$set(item, "credits", $$v)},expression:"item.credits"}})],1),_c('a-divider',{staticStyle:{"margin":"16px -16px 4px -16px","width":"auto"}}),_vm._l((item.courseGroups),function(courseGroup,idx){return _c('div',{key:courseGroup.courseGroupId || idx},[_c('a-form-model-item',{attrs:{"help":_vm.calcCourseGroupError(courseGroup) ? _vm.$t('graduation.selectCourseGroup') : '',"validateStatus":_vm.calcCourseGroupError(courseGroup) ? 'error' : '',"prop":"conditions"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"title-bar"}),_vm._v(" "+_vm._s(_vm.$t('graduation.courseGroup', { index: idx + 1 }))+" ")]},proxy:true}],null,true)},[((courseGroup.courseDetails || []).length)?_c('div',{ref:("index" + index + "_" + idx),refInFor:true,staticClass:"tag-container",staticStyle:{"margin-top":"4px"}},_vm._l((courseGroup.courseDetails),function(course,courseIndex){return _c('a-tag',{key:course.id,attrs:{"visible":"","closable":""},on:{"close":function($event){return _vm.removeItem(course.id, 'compulsory', index, idx, courseIndex)}}},[_vm._v(_vm._s(course.title))])}),1):_vm._e(),_c('a-button',{attrs:{"block":"","type":"dashed","icon":"plus","loading":_vm.dataLoading},on:{"click":function($event){return _vm.addByType('compulsory', index, idx)}}},[_vm._v(_vm._s(_vm.$t('courseModule.addCourse')))])],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('graduation.maxRecognizedCreitds'),"rules":[
                {
                  validator: _vm.validateCredit,
                  trigger: ['change', 'blur'],
                  creditType: 'section',
                  index: index,
                  idx: idx,
                } ],"prop":"conditions","required":""}},[_c('a-input-number',{attrs:{"precision":2,"min":0,"max":999,"placeholder":_vm.$t('common.enter')},on:{"change":_vm.validateCredits},model:{value:(courseGroup.maxAdmitCredits),callback:function ($$v) {_vm.$set(courseGroup, "maxAdmitCredits", $$v)},expression:"courseGroup.maxAdmitCredits"}})],1),_c('a-divider',{staticStyle:{"margin":"16px -16px 4px -16px","width":"auto"}})],1)}),_c('div',{staticClass:"add-buttom",on:{"click":function($event){return _vm.addCourseGroup(index)}}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" "+_vm._s(_vm.$t('graduation.addCourseGroup'))+" ")],1)],2)}),_c('a-button',{attrs:{"icon":"plus-circle"},on:{"click":_vm.addCourseAdmission}},[_vm._v(_vm._s(_vm.$t('graduation.addCourseAdmission')))])],2)],1),(_vm.elective.length)?_c('a-row',{attrs:{"gutter":[8, 16]}},[_c('a-col',{attrs:{"span":12,"offset":7}},[_c('div',{staticClass:"title",staticStyle:{"margin-bottom":"16px"}},[_c('div',{staticClass:"title-bar"}),_c('span',{staticClass:"title-text"},[_vm._v(_vm._s(_vm.$t('graduation.elective')))])]),_vm._l((_vm.elective),function(item,index){return _c('div',{key:item.conditionId || index,staticClass:"single-course-arrange"},[_c('a-form-model-item',{attrs:{"label":_vm.$t('graduation.title'),"rules":[
              {
                validator: _vm.validateText,
                trigger: ['change', 'blur'],
                condition: 'elective',
                textType: 'title',
                index: index,
              } ],"prop":"conditions","required":""}},[_c('a-input',{staticStyle:{"width":"100%"},attrs:{"placeholder":_vm.$t('common.enter')},model:{value:(item.title),callback:function ($$v) {_vm.$set(item, "title", $$v)},expression:"item.title"}})],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('common.instruction'),"rules":[
              {
                validator: _vm.validateText,
                trigger: ['change', 'blur'],
                condition: 'elective',
                textType: 'remark',
                index: index,
              } ],"prop":"conditions","required":""}},[_c('a-textarea',{staticStyle:{"width":"100%"},attrs:{"placeholder":_vm.$t('common.enter'),"autoSize":{
                minRows: 4,
                maxRows: 8,
              }},model:{value:(item.remark),callback:function ($$v) {_vm.$set(item, "remark", $$v)},expression:"item.remark"}})],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('graduation.mark'),"rules":[
              {
                validator: _vm.validateElectiveCredit,
                trigger: ['change', 'blur'],
                index: index,
              } ],"prop":"conditions","required":""}},[_c('a-input-number',{attrs:{"precision":2,"min":0,"max":999,"placeholder":_vm.$t('common.enter')},model:{value:(item.credits),callback:function ($$v) {_vm.$set(item, "credits", $$v)},expression:"item.credits"}})],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('graduation.course'),"help":_vm.calcCourseGroupError(item.courseGroups[0]) ? _vm.$t('graduation.selectCourseGroup') : '',"validateStatus":_vm.calcCourseGroupError(item.courseGroups[0]) ? 'error' : '',"prop":"conditions"}},[((item.courseGroups[0].courseDetails || []).length)?_c('div',{staticClass:"tag-container",staticStyle:{"margin-top":"4px"}},_vm._l((item.courseGroups[0].courseDetails),function(course,courseIndex){return _c('a-tag',{key:course.id,attrs:{"visible":"","closable":""},on:{"close":function($event){return _vm.removeItem(course.id, 'elective', index, 0, courseIndex)}}},[_vm._v(_vm._s(course.title))])}),1):_vm._e(),_c('a-button',{attrs:{"block":"","type":"dashed","icon":"plus","loading":_vm.dataLoading},on:{"click":function($event){return _vm.addByType('elective', index, 0)}}},[_vm._v(_vm._s(_vm.$t('graduation.addCourse')))])],1)],1)})],2)],1):_vm._e(),_c('a-row',{attrs:{"gutter":[8, 16]}},[_c('a-col',{attrs:{"span":12,"offset":7}},[_c('a-button',{staticStyle:{"margin-right":"8px"},attrs:{"type":"primary","loading":_vm.sending},on:{"click":_vm.validateBeforeSend}},[_vm._v(_vm._s(_vm.$t('common.save')))]),_c('a-button',{on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t('common.cancel')))])],1)],1),_c('group-transfer',{attrs:{"title":_vm.transferTitle,"defaultCandidateData":_vm.transferCandidate,"defaultSelectedData":_vm.transferSelected},on:{"confirm":_vm.getSelectedData},model:{value:(_vm.groupTransferVisible),callback:function ($$v) {_vm.groupTransferVisible=$$v},expression:"groupTransferVisible"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }