import { render, staticRenderFns } from "./MiddleSchoolGrading.vue?vue&type=template&id=63d6697e&scoped=true&"
import script from "./MiddleSchoolGrading.vue?vue&type=script&lang=ts&"
export * from "./MiddleSchoolGrading.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63d6697e",
  null
  
)

export default component.exports